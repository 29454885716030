import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Slide } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send";
import { Box, Typography, Button, Dialog, AppBar, Toolbar, Avatar, IconButton, DialogContent, DialogActions, Paper, InputBase, CircularProgress } from "@material-ui/core";
import * as API from "../constants/ApiConstants";
import { w3cwebsocket as WebSocketClient } from 'websocket';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let sameMessage = false;

const Messages = ({ message, type }) => {
  return (
    <>
      <Box
        sx={{
          bgcolor: type === "send" ? "#0b2b65" : "#fff",
          color: type === "send" ? "#fff" : "#0b2b65",
          p: 2,
          m: 1,
          borderRadius: 2,
          maxWidth: "60%",
          ml: type === "send" ? "auto" : 0,
        }}
      >
        <Box sx={{ overflow: 'auto' }}>
          <Typography>
            {message.map((item, index) => (
              <>
                {item}
              </>
            ))}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const ChatBox = () => {
  const [threadId, setThreadId] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [open, setOpen] = useState(false);
  const [chatList, setChatList] = useState([
    // {
    //   message: ["Hi!"],
    //   type: "send",
    // },
    // {
    //   message: ["Hi there! Nice to see you 😊."],
    //   type: "received",
    // },
  ]);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const clientRef = useRef(null); // Reference to keep track of the WebSocket client

  useEffect(() => {
    console.log("data", chatList);
  }, [chatList]);

  // const handleSearch = async () => {
  //   sameMessage = false;
  //   setLoading(true); // Set loading state to true before making API call

  //   setChatList((prevData) => [
  //     ...prevData,
  //     {
  //       message: [prompt],
  //       type: "send",
  //     },
  //   ]);
  //   const data = {
  //     query: prompt,
  //   };
  //   setPrompt("");
  //   try {
  //     var tokenData = JSON.parse(localStorage.getItem("shop360User")).token;
  //     try {
  //       const res = await fetch(`${API.API_URL}chatbot/query`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + tokenData,
  //         },
  //         body: JSON.stringify(data),
  //       });

  //       const response = await res.json();
  //       sameMessage = false;
  //     } catch (error) {
  //       sameMessage = false;
  //       setChatList((prevChatList) => {
  //         return [
  //           ...prevChatList,
  //           {
  //             message: ["No data found"],
  //             type: "received",
  //           }
  //         ];
  //       });
  //       console.log(error);
  //     }
  //   } catch (error) {
  //     sameMessage = false;
  //     setChatList((prevChatList) => {
  //       return [
  //         ...prevChatList,
  //         {
  //           message: ["No data found"],
  //           type: "received",
  //         }
  //       ];
  //     });
  //     console.error(error);
  //   }
  //   setLoading(false); // Set loading state to false after API call completes
  //   setPrompt("");
  // };

  const start = async () => {
    setLoading(true); // Set loading state to true before making API call
    try {
      var tokenData = JSON.parse(localStorage.getItem("shop360User")).token;
      try {
        const res = await fetch(`${API.API_URL}chatbot/start`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenData,
          }
        });

        const response = await res.json();
        setThreadId(response.thread_id);

      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearch = async () => {
    sameMessage = false;
    setLoading(true); // Set loading state to true before making API call

    setChatList((prevData) => [
      ...prevData,
      {
        message: [prompt],
        type: "send",
      },
    ]);
    const data = {
      "thread_id": threadId,
      "userInput": prompt
    }
    setPrompt("");
    try {
      var tokenData = JSON.parse(localStorage.getItem("shop360User")).token;
      try {
        const res = await fetch(`${API.API_URL}chatbot/chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenData,
          },
          body: JSON.stringify(data),
        });

        const response = await res.json();
        setChatList((prevChatList) => {
          return [
            ...prevChatList,
            {
              message: [response.response],
              type: "received",
            }
          ];
        });
        sameMessage = false;
      } catch (error) {
        sameMessage = false;
        setChatList((prevChatList) => {
          return [
            ...prevChatList,
            {
              message: ["No data found"],
              type: "received",
            }
          ];
        });
        console.log(error);
      }
    } catch (error) {
      sameMessage = false;
      setChatList((prevChatList) => {
        return [
          ...prevChatList,
          {
            message: ["No data found"],
            type: "received",
          }
        ];
      });
      console.error(error);
    }
    setLoading(false); // Set loading state to false after API call completes
    setPrompt("");
  };

  const handleInputChange = (event) => {
    setPrompt(event.target.value);
  };

  const chatContainerRef = useRef(null);

  const connectWebSocket = () => {
    var userId = JSON.parse(localStorage.getItem("shop360User")).user_details._id;
    clientRef.current = new WebSocketClient(`ws://localhost:3001?userId=${userId}`);
    //clientRef.current = new WebSocketClient(`wss://shop360.ai/ws/?userId=${userId}`);
    clientRef.current.onmessage = (responseMessage) => {
      if (sameMessage) {
        setChatList((prevChatList) => {
          let updatedChatList = [...prevChatList];
          let lastObject = updatedChatList[updatedChatList.length - 1];
          lastObject.message.push(responseMessage.data);
          // Convert the list to a Set to remove duplicates
          let uniqueSet = new Set(lastObject.message);

          // Convert the Set back to an array
          let uniqueList = [...uniqueSet];
          lastObject.message = uniqueList;
          return updatedChatList;
        });
      } else {
        sameMessage = true;
        setChatList((prevChatList) => {
          return [
            ...prevChatList,
            {
              message: [responseMessage.data],
              type: "received",
            }
          ];
        });
      }
    };

    clientRef.current.onopen = () => {
      console.log('WebSocket connection opened');
    };

    clientRef.current.onclose = () => {
      console.log('WebSocket connection closed, attempting to reconnect');
      setTimeout(() => {
        connectWebSocket();
      }, 1000);
    };
  };

  // useEffect(() => {
  //   connectWebSocket();
  //   return () => {
  //     if (clientRef.current) {
  //       clientRef.current.close();
  //     }
  //   };
  // }, []);

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (open && chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatList, open]);

  const handleReset = () => {
    start();
    setChatList([
      // {
      //   message: ["Hi!"],
      //   type: "send",
      // },
      // {
      //   message: ["Hi there! Nice to see you 😊."],
      //   type: "received",
      // },
    ]);
    setPrompt("");
    if (clientRef.current) {
      clientRef.current.close();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        style={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          zIndex: 9999,
          backgroundColor: "#0b2b65",
          color: "#fff",
        }}
      >
        Chat with us
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "800px",
            height: "100%",
            maxHeight: "100%",
            margin: "0 auto",
            background: "#eee",
          },
        }}
      >
        <AppBar style={{ position: "relative", background: "#0b2b65" }}>
          <Toolbar>
            <Avatar src="./demo_logo.png" />
            <Box ml={2} style={{ flex: 1 }}>
              <Typography variant="body2" component="div">
                Chat With Us
              </Typography>
              <Typography variant="h6" component="div">
                Shop360 AI Bot
              </Typography>
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
              size="small"
            >
              <ExpandMoreIcon fontSize="large" />
            </IconButton>
          </Toolbar>
          <Toolbar
            style={{
              paddingTop: "8px",
              paddingBottom: "8px",
              minHeight: "0px !important",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" component="div">
              We're Online
            </Typography>
            <Box
              sx={{
                height: "10px",
                width: "10px",
                borderRadius: "100%",
                bgcolor: "#23dc06",
              }}
            ></Box>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            height: "--webkit-fill-available",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            maxHeight: "70vh",
            padding: "16px",
          }}
          ref={chatContainerRef}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
            }}
          >
            {chatList.map((res, index) => (
              <Messages message={res.message} type={res.type} key={index} />
            ))}
          </Box>
          {loading && <CircularProgress style={{ alignSelf: 'center', margin: '16px' }} />}
        </DialogContent>
        <DialogActions>
          <Paper
            component="form"
            style={{
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              margin: "auto",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <InputBase
              style={{ marginLeft: "8px", flex: 1 }}
              placeholder="Enter your query"
              inputProps={{ "aria-label": "enter your query" }}
              autoFocus
              value={prompt}
              onChange={handleInputChange}
            />
            <IconButton type="submit" style={{ padding: "10px" }} aria-label="search" disabled={!prompt || prompt === ""}>
              <SendIcon sx={{ color: "#0b2b65" }} />
            </IconButton>
            <Tooltip title="Reset">
              <IconButton
                style={{ padding: "10px" }}
                aria-label="reset"
                onClick={handleReset}
              >
                <RestartAltIcon sx={{ color: "#0b2b65" }} />
              </IconButton>
            </Tooltip>
          </Paper>
        </DialogActions>
      </Dialog >
    </>
  );
}

export default ChatBox;
