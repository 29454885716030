import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

import { ToastContainer } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';

import ScrollToTop from './utils/ScrollToTop';
import ChatBox from './components/ChatBox';

function App() {
  return (
    <div>
      <BrowserRouter basename="/">
        <CssBaseline />
        <ScrollToTop>
          <Routes />
          {localStorage.getItem("shop360LoggedIn") && <ChatBox />}
        </ScrollToTop>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
