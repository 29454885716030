// export const API_URL = process.env.REACT_APP_API_URL
// export const API_URL = 'http://207.244.251.200:3000/api/v1/'
//export const API_URL = 'http://3.230.233.104:3000/api/v1/'
//export const API_URL = 'http://localhost:3001/api/v1/'
export const API_URL = 'api/v1/'

// export const API_URL = ' https://shop360.ai/api/v1/';
export const IMG_URL = API_URL + "images?url=";
export const appShortName = "AI";

//Vehicle controller
export const SEARCH_PRODUCTS_BY_FILTER_WITH_PAGINATION_URL = API_URL + "product/filter/products"
export const EXPORT_PRODUCTS_URL = API_URL + "product/filter/products/export"
export const EXPORT_PRODUCTS_LINKS_URL = API_URL + "product/filter/products/links/export"
export const GET_PRODUCT_BY_ID_URL = API_URL + "product/get/product"
export const GET_RECENT_PRODUCTS_URL = API_URL + "product/recent"

//File controller
export const GETFILE_URL = API_URL + "get/image/"

//Authentication controller
export const LOGIN_URL = API_URL + "auth/login"

//User controller
export const ADDUSER_URL = API_URL + "user/add"
export const CONTACTUS_URL = API_URL + "user/contactUs"

//Forgot Password
export const FORGOT_PASSWORD = API_URL + "user/forgotPassword/"

//Change Password
export const CHANGE_PASSWORD = API_URL + "user/changePassword/"