import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Pages
const Homepage = lazy(() => import('./pages/Homepage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const ProductListPage = lazy(() => import('./pages/ProductListPage'));
const ProductDetailsPage = lazy(() => import('./pages/ProductDetailsPage'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    // <ThemeProvider theme={MuiTheme}>
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">
              Please wait while we load
            </div>
          </div>
        }>
        <Switch>
          <Redirect exact from="/" to="/LandingPage" />
          <Route path={['/Homepage']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/Homepage" component={Homepage} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/LandingPage']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/LandingPage" component={LandingPage} >
                  {/* <Route path="/LandingPage/:status" component={LandingPage} ></Route> */}
                </Route>
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/ProductListPage']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/ProductListPage" component={ProductListPage} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/ProductDetailsPage']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/ProductDetailsPage" component={ProductDetailsPage} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/AboutUs']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/AboutUs" component={AboutUs} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/ContactUs']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/ContactUs" component={ContactUs} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/Login']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/Login" component={Login} />
              </motion.div>
            </Switch>
          </Route>
          <Route path={['/Register']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/Register" component={Register} />
              </motion.div>
            </Switch>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
